
import axios from 'axios';
axios.defaults.withCredentials = true;

import RequestNewApplicationFromSearch from './RequestNewApplicationFromSearch'
import LoginPopupTransparent from '../LoginPopupTransparent.vue';
import AppByCategoryItemComponent from './AppByCategoryItemComponent.vue';
import SearchItemComponent from './SearchItemComponent.vue';
import SearchesAndAppsByCategory from './SearchesAndAppsByCategory.vue';
import SimilarAppListComponent from './SimilarAppListComponent.vue';
import UpcomingAppListComponent from './UpcomingAppListComponent.vue';
import SearchItemShimmer from './SearchItemShimmer.vue';
import AppByCategoryItemShimmer from './AppByCategoryItemShimmer.vue';

export default {
    created() {
        const context = this;
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'setShowSearchPopup') {
                context.showSearchPopup = mutation.payload
            }
        });
    },
    data() {
        return {
            isLoginPopupVisible: false,
            isStoringNewAppRequest: false,
            isRequestFormVisible: false,
            isRequestButtonVisible: false,
            isSubmissiomSuccess: false,
            isFetchingSearchSuggestions: false,
            appshotSubscribeSlug: '',
            selectedCategoryItemIndex: null,
            showSearchPopup: false,
            searchTerm: '',
            currentlyLoadingTerm: '',
            highlightedPopularItemIndex: -1,
            highlightedCategoryIndex: -1,
            highlightedType: '',
            highlightedIndex: -1,
            timeoutId: null,
            popularSearchResults: [],
            appCategoryList: [],
            appCategoryResultList: [],
            searchResults: [],
            upcomingAppList: [],
            similarAppList: [],
            selectedCategory: "Popular Searches",
        };
    },
    computed: {
        showResultCount() {
            if (!!this.searchTerm) {
                const totalResultCount = this.searchResults.length
                return totalResultCount
            }
            if (this.selectedCategory === 'Popular Searches') {
                const totalResultCount = this.popularSearchResults.length
                return totalResultCount
            }
            return '0'
        }
    },
    methods: {
        getSearchSuggestions() {
            this.isFetchingSearchSuggestions = true
            axios
                .get(`${process.env.main_API_URL}/get_search_suggestions/`).then(response => {
                    this.isFetchingSearchSuggestions = false
                    if (response && response.status && response.status === 200) {
                        this.popularSearchResults = response.data.app_shots
                        this.appCategoryList = response.data.categories
                    }
                }
                ).catch(error => {
                    this.isFetchingSearchSuggestions = false
                    this.popularSearchResults = []
                    this.appCategoryList = []
                })
        },
        onHandleGetNotificationClick(payload) {
            const token = this.$store.state.isAuthenticated;
            if (!token) {
                this.isLoginPopupVisible = true
                return
            }
            if (this.$store.state.appshotSubscribeSlug) return
            this.$store.commit("setAppshotSubscribeSlug", payload);
            // UpVote API Goes here
            axios
                .get(`${process.env.main_API_URL}/upvote_appshot?appshot_slug=${payload}`).then(response => {
                    if (response && response.status && response.status === 200) {
                        // Push/Pop upvotedAppShots global state
                        this.updateUpvotedAppShots(payload, response.data.toast)
                        this.showToaster(response.data.toast);
                        this.$store.commit("setAppshotSubscribeSlug", '');
                    }
                }
                ).catch(error => {
                    this.showToaster("Something bad happened", 'error');
                    this.$store.commit("setAppshotSubscribeSlug", '');
                })
        },
        updateUpvotedAppShots(appslug, upvotedCase) {
            if (upvotedCase === 'Upvoted') {
                this.$store.commit("addSelectedUpvotedAppShots", appslug);
            }
            if (upvotedCase === 'Upvote removed') {
                const filteredData = this.$store.state.upvotedAppShots.filter(item => item !== appslug);
                this.$store.commit("removeSelectedUpvotedAppShots", filteredData);
            }
        },
        onHideLoginTransparentPopup() {
            this.isLoginPopupVisible = false
        },
        scrollToItem(amount) {
            this.$refs.resultContainer.scrollBy({ top: amount, behavior: 'smooth' });
        },
        redirect(app) {
            this.closePopup()
            document.body.classList.remove('disable-scroll');
            this.$router.push(`/apps/${app.slug}`)
        },
        onPressCategory(item) {
            if (item === "Popular Searches") {
                this.selectedCategoryItemIndex = -1
                this.selectedCategoryItemIndex = null;
            } else {
                this.highlightedPopularItemIndex = -1;
            }
            this.selectedCategory = item;
        },
        onPressSelectedAppCategoryItem() {
            this.highlightedPopularItemIndex = -1;
            this.appCategoryResultList = []
            this.selectedCategoryItemIndex = null
        },
        onPressAppCategoryItem(appItemIndex) {
            if (appItemIndex < 0) return
            let category = this.appCategoryList[appItemIndex].name
            this.$router.push("/apps/");
            this.$store.commit('setSelectedCategories', [category])
            this.$store.commit("setReloadPage");
            this.closePopup()
        },
        getCurrentItemHighlighted(index, type) {
            switch (type) {
                case 'searchItems':
                    return this.highlightedIndex === index && this.highlightedType === 'searchItems';
                case 'upcomingItems':
                    return this.highlightedIndex - this.searchResults.length === index && this.highlightedType === 'upcomingItems';
                case 'similarItems':
                    return this.highlightedIndex - this.searchResults.length - this.upcomingAppList.length === index && this.highlightedType === 'similarItems';
                default:
                    return false;
            }
        },
        getCurrentItemType() {
            if (this.highlightedIndex < this.searchResults.length) {
                return 'searchItems';
            } else if (this.highlightedIndex < this.searchResults.length + this.upcomingAppList.length) {
                return 'upcomingItems';
            } else {
                return 'similarItems';
            }
        },
        getMaxItemIndex() {
            return this.searchResults.length + this.upcomingAppList.length + this.similarAppList.length - 1
        },
        closePopup() {
            this.showSearchPopup = false;
            this.searchTerm = '';
            this.currentlyLoadingTerm = '';
            this.isRequestFormVisible = false;
            this.isRequestButtonVisible = false;
            this.highlightedPopularItemIndex = -1;
            this.highlightedType = '';
            this.highlightedIndex = -1;
            this.timeoutId = null;
            this.searchResults = [];
            this.upcomingAppList = [];
            this.similarAppList = [];
            this.selectedCategory = "Popular Searches";
        },
        search() {
            this.isRequestButtonVisible = false
            if (this.searchTerm.length <= 0) {
                this.searchTerm = '';
                this.currentlyLoadingTerm = '';
                this.highlightedPopularItemIndex = -1;
                this.highlightedType = '';
                this.highlightedIndex = -1;
                this.timeoutId = null;
                this.searchResults = [];
                this.upcomingAppList = [];
                this.similarAppList = [];
                clearTimeout(this.timeoutId)
                return
            }
            if (this.timeoutId) {
                clearTimeout(this.timeoutId)
            }
            this.timeoutId = setTimeout(() => {
                this.fetchResults()
            }, 300)
        },
        async fetchResults() {
            if (this.searchTerm.length < 3) return;
            if (this.currentlyLoadingTerm === this.searchTerm) return;
            // update currentlyLoadingTerm variable
            this.currentlyLoadingTerm = this.searchTerm;
            var url = `${process.env.main_API_URL}/search_app_shots/`;
            try {
                const response = await this.$axios.get(url, {
                    params: {
                        q: this.searchTerm,
                    }
                })
                if (this.searchTerm !== response.data.q) return;
                this.currentlyLoadingTerm = '';
                this.searchResults = response.data.app_shots;
                this.upcomingAppList = response.data.upcoming_apps;
                this.similarAppList = response.data.similar_apps;
                if (this.searchResults.length === 0 && this.upcomingAppList.length === 0 && this.similarAppList.length === 0) {
                    this.isRequestButtonVisible = true
                }
            } catch (error) {
                this.currentlyLoadingTerm = '';
                this.searchResults = []
            }
        },
        onClickRequestNewApplication() {
            // if not authenticated
            const token = this.$store.state.isAuthenticated;
            if (!token) {
                this.isLoginPopupVisible = true
                return
            }
            this.isRequestFormVisible = true;
        },
        onCloseRequestNewApplicationForm() {
            this.isRequestFormVisible = false;
        },
        onCancelRequestNewApplicationSuccessMessage() {
            this.isSubmissiomSuccess = false
        },
        onSubmitAppRequest(payload) {
            // Authentication
            const token = this.$store.state.isAuthenticated;
            if (!token) {
                this.isLoginPopupVisible = true
                return
            }
            // Submit the form
            var requestNewApp = new FormData();
            requestNewApp.append('app_name', payload.app_name)
            requestNewApp.append('platforms', payload.platforms)
            requestNewApp.append('notes', payload.notes)
            requestNewApp.append('app_region', payload.app_region)
            this.isStoringNewAppRequest = true
            axios
                .post(`${process.env.main_API_URL}/request_new_app/`, requestNewApp).then(response => {
                    this.isStoringNewAppRequest = false
                    if (response && response.status && response.status === 200) {
                        this.isSubmissiomSuccess = true
                        this.showToaster(response.data.toast);
                    }
                }
                ).catch(error => {
                    this.isStoringNewAppRequest = false
                    this.isRequestFormVisible = false;
                    this.isSubmissiomSuccess = false
                    this.showToaster("Something bad happened", 'error');
                })
        },
        handleKeypress(event) {
            const maxItemIndex = this.getMaxItemIndex();
            const popularSearchResultsLength = this.popularSearchResults.length;
            const appCategoryListLength = this.appCategoryList.length;
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                if (!!this.searchTerm) {
                    this.highlightedIndex = Math.min(this.highlightedIndex + 1, maxItemIndex);
                    this.highlightedType = this.getCurrentItemType();
                    this.scrollToItem(80)
                    if (this.highlightedIndex === this.searchResults.length) {
                        this.scrollToItem(60);
                    }
                    if (this.highlightedIndex === (this.searchResults.length + this.upcomingAppList.length)) {
                        this.scrollToItem(60);
                    }
                } else if (this.selectedCategory === 'Popular Searches') {
                    // Popular Searches
                    if (this.highlightedPopularItemIndex < (popularSearchResultsLength - 1)) {
                        this.highlightedPopularItemIndex += 1
                    }
                    this.scrollToItem(60);
                } else {
                    // Apps by Category
                    if (this.highlightedCategoryIndex < (appCategoryListLength - 1)) {
                        this.highlightedCategoryIndex += 1
                    }
                    this.scrollToItem(10);
                }
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                if (!!this.searchTerm) {
                    this.highlightedIndex = Math.max(this.highlightedIndex - 1, 0);
                    this.highlightedType = this.getCurrentItemType();
                    this.scrollToItem(-80)
                    if (this.highlightedIndex === this.searchResults.length) {
                        this.scrollToItem(-60);
                    }
                    if (this.highlightedIndex === (this.searchResults.length + this.upcomingAppList.length)) {
                        this.scrollToItem(-60);
                    }
                } else if (this.selectedCategory === 'Popular Searches') {
                    // Popular Searches
                    if (this.highlightedPopularItemIndex > 0) {
                        this.highlightedPopularItemIndex -= 1
                    }
                    this.scrollToItem(-60);
                } else {
                    // Apps by Category
                    if (this.highlightedCategoryIndex > 0) {
                        this.highlightedCategoryIndex -= 1
                    }
                    this.scrollToItem(-10);
                }
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (this.searchTerm.length > 0) {
                    const allAppList = [...this.searchResults, ...this.upcomingAppList, ...this.similarAppList]
                    if (this.highlightedType === 'upcomingItems') return
                    let app = allAppList[this.highlightedIndex];
                    this.redirect(app)
                } else if (this.selectedCategory === 'Popular Searches') {
                    if (this.highlightedPopularItemIndex < this.popularSearchResults.length && this.highlightedPopularItemIndex > -1) {
                        let app = this.popularSearchResults[this.highlightedPopularItemIndex]
                        this.redirect(app);
                    }
                } else {
                    if (this.highlightedCategoryIndex < this.appCategoryList.length && this.highlightedCategoryIndex > -1) {
                        this.onPressAppCategoryItem(this.highlightedCategoryIndex)
                    }
                }
            } else if (event.key === 'ArrowRight') {
                if (this.selectedCategory === 'Popular Searches') {
                    this.selectedCategory = 'Apps by Category'
                } else {
                    this.selectedCategory = 'Popular Searches'
                }
            } else if (event.key === 'ArrowLeft') {
                if (this.selectedCategory === 'Popular Searches') {
                    this.selectedCategory = 'Apps by Category'
                } else {
                    this.selectedCategory = 'Popular Searches'
                }
            }
            else if (event.key === 'Escape') {
                event.preventDefault();
                this.closePopup();
            } else {
                // console.log(event.key);
            }
        },
    },
    mounted() {
        // Get Popular Search
        this.getSearchSuggestions()
    },
    watch: {
        showSearchPopup: function (value) {
            if (value) {
                document.body.classList.add('disable-scroll');
                setTimeout(() => {
                    this.$refs.searchInput.focus();
                }, 100);
            } else {
                document.body.classList.remove('disable-scroll');
                document.removeEventListener('keydown', this.closePopup);
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.closePopup);
    },
    components: { SearchesAndAppsByCategory, SearchItemComponent, UpcomingAppListComponent, SimilarAppListComponent, AppByCategoryItemComponent, LoginPopupTransparent, RequestNewApplicationFromSearch, SearchItemShimmer, AppByCategoryItemShimmer }
}
