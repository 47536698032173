
export default {
    props: {
        itemIndex: Number,
        itemDetail: Object,
        selectedCategoryItemIndex: Number,
        onPressAppCategoryItem: Function
    },
    data() {
        return {
            isHovered: false,
        };
    },
    computed: {
        selectedClass() {
            if (this.selectedCategoryItemIndex === this.itemIndex) {
                return 'app-category-item-container-selected'
            }
            return this.isHovered ? 'app-category-item-container-selected' : 'app-category-item-container';
        },
    },
    methods: {
        onSelectCategoryItem() {
            this.onPressAppCategoryItem(this.itemIndex)
        }
    }
}
