
import SearchItemComponent from './SearchItemComponent.vue';

export default {
    props: {
        upcomingAppList: Array,
        onHandleGetNotificationClick: Function,
        getCurrentItemHighlighted: Function
    },
    methods: {
        handleClickOnItem() {
            // Dummy function
        },
    },
    components: { SearchItemComponent }
}
