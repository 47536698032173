
import axios from 'axios';
axios.defaults.withCredentials = true;
import UpgradePopup from '../components/UpgradePopup.vue'
import Onboarding from '../components/Onboarding.vue'
import "../static/css/app.css"

export default {
    components: { UpgradePopup, Onboarding },
    data() {
        return {
            onboardVisible: false
        }
    },
    created() {
        let context = this
        var url = `${process.env.main_API_URL}/get_configurations/`;
        let referralID = this.$store.state.referralID
        if (referralID && referralID.length > 0) url += `?referral_id=${referralID}`

        axios.get(url).then(function (response) {
            let data = response.data
            sessionStorage.isAnyCollectionAvailable = data.configurations.has_created_first_collection
            // Membership
            if (data.membership) {
                context.$store.commit("setAsPro", data.membership == "PRO");
            }
            // Other configurations
            context.$store.commit("setConfigurations", data.configurations)
            context.$store.commit("setDynamicSnackBar", data.dynamic_snack_bar)

            // Set the user authentication data.is_authenticated
            if (data.is_authenticated) {
                // already authenticated
                if (data.configurations) {
                    if (data.configurations.survey_completed === false || data.configurations.survey_completed === null) {
                        // Code to show onboarding user survey popup goes here
                        context.onboardVisible = true
                    }
                }
            } else {
                context.$store.commit("globalUserDataUpdate", {});
                context.$store.commit("setAuthenticated", false);
            }
        })

        // discount code saving to store
        if (this.$route.query.discount && this.$route.query.discount.length > 0) {
            this.$store.commit('setDiscountCode', this.$route.query.discount);
        }
    },
    computed: {
        isLoginPopupVisible() {
            return this.$store.getters.isLoginPopupVisible;
        },
        showUpgradePopup() {
            return this.$store.getters.isUpgradePopupVisible
        },
        currentUser() {
            return this.$store.state.globalUserData;
        },
    },
    mounted() {
        this.$store.commit('hideLoginPopup');
        this.$store.commit('hideUpgradePopup');
    },
}
