
import firebase from "firebase/compat/app";

// https://stackoverflow.com/a/54235868
import axios from "axios";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default {
    props: {
        onHideLoginTransparentPopup: { type: Function, required: true }
    },
    methods: {
        // UI
        canShowEmailPasswordInput() {
            console.log("canShowEmailPasswordInput");
            // password_id.hidden = validateEmail(this.email_id.value);
        },
        // Auth
        googleAuth() {
            let context = this;
            var provider = new firebase.auth.GoogleAuthProvider();
            provider.setCustomParameters({
                login_hint: "martin@gmail.com",
            });

            firebase
                .auth()
                .signInWithPopup(provider)
                .then((result) => {
                    /** @type {firebase.auth.OAuthCredential} */
                    var user = result.user;
                    context.$nuxt.$loading.start()
                    context.getIdTokenAndValidate(user);
                })
                .catch((error) => {
                    // Handle error
                });
        },
        getIdTokenAndValidate(user) {
            firebase
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(function (idToken) {
                    this.firebaseAuthValidate(idToken, user);
                }.bind(this));
        },
        firebaseAuthValidate(idToken, user) {
            const url = `${process.env.main_API_URL}/firebase_auth_validate/`;
            const bodyFormData = new FormData();
            bodyFormData.append("id_token", idToken);

            axios({
                method: "post",
                url: url,
                data: bodyFormData,
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${bodyFormData._boundary}`,
                },
            }).then((response) => {
                const user = response.data;
                this.proceedToNext(user);
            }).catch((error) => {
                // Handle error
            });
        },


        facebookAuth() {
            let context = this;
            var provider = new firebase.auth.FacebookAuthProvider();
            firebase
                .auth()
                .signInWithPopup(provider)
                .then((result) => {
                    var user = result.user;
                    context.$nuxt.$loading.start();
                    context.getIdTokenAndValidate(user);
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        loginWithDribbble() {
            const clientId = process.env.DRIBBBLE_CLIENT_ID
            const redirectUri = encodeURIComponent(process.env.DRIBBBLE_REDIRECT_URI)
            const url = `https://dribbble.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`
            window.location.href = url
        },
        twitterAuth() {
            this.$nuxt.$loading.start()
            let context = this;
            var provider = new firebase.auth.TwitterAuthProvider();
            firebase
                .auth()
                .signInWithPopup(provider)
                .then((result) => {
                    var user = result.user;
                    context.$nuxt.$loading.start();
                    context.getIdTokenAndValidate(user);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        appleAuth() {
            let context = this;
            var provider = new firebase.auth.OAuthProvider("apple.com");
            firebase
                .auth()
                .signInWithPopup(provider)
                .then((result) => {
                    var user = result.user;
                    context.$nuxt.$loading.start();
                    context.getIdTokenAndValidate(user);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        emailAuth() {

            let email = email_id.value;
            let password = password_id.value;
            // Show toast if invalid
            if (!this.validateEmail(email)) {
                this.showToaster("You have entered an invalid email address!", "error");
                return;
            }

            // if (password_id.style.display == "none") {
            //   password_id.style.display = "block";
            //   return;
            // }

            this.$nuxt.$loading.start()

            let context = this;
            firebase
                .auth()
                .sendSignInLinkToEmail(email, {
                    url: "https://appshots.design/",
                    handleCodeInApp: true
                })
                .then(() => {
                    // window.localStorage.setItem("emailForSignIn", this.email);
                    context.$nuxt.$loading.start();
                    // context.$router.push("/check-email");
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // Validators
        validateEmail(mail) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
                return true;
            }
            return false;
        },
        // Login flow
        proceedToNext(user) {
            this.$store.commit("globalUserDataUpdate", user);
            this.$store.commit("setAuthenticated", true);

            // All data available
            this.openRedirectionUrl();
        },
        // Sign up for new user.
        emailSignUp() {
            let email = email_id.value;
            let password = password_id.value;

            firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then((data) => {

                    this.createNewUserRecord(data.user);
                })
                .catch((err) => {

                    this.showToaster(err.message, "error");
                });
        },
        async createNewUserRecord(user) {
            var userData = {
                id: user.uid,
                type: "profile",
                username: "",
                displayName: user.displayName,
                email: user.email,
                photo_url: user.photo_url,
                user_symbol: "🎃",
            };

            this.$store.commit("globalUserDataUpdate", userData);
            this.$store.commit("setAuthenticated", true);

            let newProfileKey = this.profiles.push().key;
            await this.profiles.child(newProfileKey).set(userData);

            this.openRedirectionUrl();
        },
        openRedirectionUrl() {
            let redirection_url = this.$route.query.redirection_url;

            if (redirection_url) {
                window.location.href = decodeURIComponent(redirection_url);
            } else {
                window.open("/", "_self");
            }
        },
    },
};
