
export default {
    data() {
        return {
            currencyCode: 'USD',
            offerings: {
                PRO: {
                    1: { product_key: '1m-pro', name: 'Monthly', terms: 1, actual_price: 36, offer_price: 29, price: 29, discount_percentage: 0, currency: 'USD' },
                    3: { product_key: '3m-pro', name: 'Quarterly', terms: 3, actual_price: 21, offer_price: 15, price: 45, discount_percentage: 30, currency: 'USD' },
                    12: { product_key: '12m-pro', name: 'Yearly', terms: 12, actual_price: 21, offer_price: 12, price: 144, discount_percentage: 43, currency: 'USD' },
                    0: { product_key: 'lifetime-pro', name: 'Lifetime', terms: 0, actual_price: 249, offer_price: 249, price: 249, discount_percentage: 0, currency: 'USD' }
                }
            }
        }
    },
    methods: {
        subscribeStripePlan(dataset) {
            if (!this.isAuthenticated()) {
                // this.showToaster("Please login");
                this.$store.commit('setLoginPopupTitle', 'Login to Appshots')
                this.showLoginPopup();
                return;
            }

            let term = parseInt(dataset.term);
            let entitlement = dataset.entitlement;
            let product = this.offerings[entitlement][term];
            if (product.price === 0) {
                // this.showToaster("It's free!");
                return;
            }

            let sessionID = this.createCheckoutSession(product);
            sessionID.then((sessionID) => {
                this.stripeCheckout(sessionID)
            })
        },
        async createCheckoutSession(product) {
            let context = this;
            let product_key = product.product_key;
            context.$nuxt.$loading.start();

            let url = `${process.env.main_API_URL}/stripe/create-checkout-session/`;
            // form data with product key
            let data = new FormData();
            data.append("product_key", product_key);
            let currentURL = new URL(window.location.href);
            data.append("base_url", `${currentURL.origin}`);
            // coupon_id
            // get discount code from url params using router
            if (this.$route.query.discount && this.$route.query.discount.length > 0) {
                this.$store.commit('setDiscountCode', this.$route.query.discount)
                data.append("coupon_id", this.$route.query.discount);
            } else if (context.$store.state.discountCode) {
                data.append("coupon_id", context.$store.state.discountCode);
            } else if (context.discountCode) {
                data.append("coupon_id", context.discountCode);
            }

            if (context.currencyCode)
                data.append("currency_code", context.currencyCode);

            const response = await this.$axios.$post(url, data)
            context.$nuxt.$loading.finish()
            return response
        },
        async stripeCheckout(sessionID) {
            const stripe = await this.getStripe()
            await stripe.redirectToCheckout({
                sessionId: sessionID
            })
        },
        closePopup() {
            this.$store.commit('hideUpgradePopup');
        }
    }
}
