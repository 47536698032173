
import axios from "axios";
import DynamicSnackBar from "./DynamicSnackBar.vue";
axios.defaults.withCredentials = true;

export default {
  props: ["theme"],
  data() {
    return {
      options: [
        { text: "iOS", value: "ios" },
        { text: "Android", value: "android" },
        { text: "Web", value: "web" },
      ],
      selected: this.$store.state.selectedPlatform,
      isDropdownActive: false,
      isReferBoxActive: false,
      showCommonMenu: false,
      showUserMenu: false,
      showMusicBox: false,
      activeListeners: this.getRandomNumber(),
      rainForestListener: 12,
      monsoonDaysListener: 15,
      officeHoursListener: 9,
      lofiFocusListener: 11,
      scrollEventAdded: false, // Data property to track if scroll event listener has been added
    };
  },
  mounted: function () {
    this.getHeaderHeight(); // Calls the method before page loads
    window.addEventListener('resize', this.getHeaderHeight);
    if (this.siteTheme === 'dark') {
      document.documentElement.classList.add('dark-mode');
      document.documentElement.classList.remove('light-mode');
    } else if (this.siteTheme === 'light') {
      document.documentElement.classList.remove('dark-mode');
      document.documentElement.classList.add('light-mode');
    } else {
      document.documentElement.classList.remove('dark-mode');
      document.documentElement.classList.remove('light-mode');
    }
    // Update activeListeners every 30 sec
    this.interval = setInterval(() => {
      this.activeListeners = this.getRandomNumber();
    }, 30000); // 30000 milliseconds = 30 sec
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.interval);
    window.removeEventListener('resize', this.getHeaderHeight); // Remove the event listener when the component is destroyed
  },
  methods: {
    getRandomNumber() {
      // Generate a random number; adjust min and max as needed
      const min = 2; // Minimum number of listeners
      const max = 9; // Maximum number of listeners
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    toggleMusicDiv() {
      this.showMusicBox = !this.showMusicBox
      this.$store.commit('setActiveMusic', this.$store.state.musicPlaying ? this.$store.state.activeMusic : 0)
    },
    startMusicPlay(index) {
      this.$store.commit('setMusicPlaying', true)
      this.$store.commit('setActiveMusic', this.$store.state.activeMusic === index ? 0 : index)
    },
    overlayClick() {
      this.showCommonMenu = false;
      this.showUserMenu = false;
      this.showMusicBox = false;
    },
    setThemeLight() {
      this.showCommonMenu = false;
      this.$store.commit('setSiteThemeLight');
      document.documentElement.classList.remove('dark-mode');
      document.documentElement.classList.add('light-mode');
    },
    setThemeDark() {
      this.showCommonMenu = false;
      this.$store.commit('setSiteThemeDark');
      document.documentElement.classList.add('dark-mode');
      document.documentElement.classList.remove('light-mode');
    },
    setThemeSystem() {
      this.showCommonMenu = false;
      this.$store.commit('setSiteThemeSystem');
      document.documentElement.classList.remove('dark-mode');
      document.documentElement.classList.remove('light-mode');
    },
    showLoginPopup() {
      // store the current url in the query string to redirect back after login
      this.$router.push({ query: { redirection_url: encodeURIComponent(window.location.href) } });
      this.$store.commit('setLoginPopupTitle', 'Welcome Back')
      this.$store.commit('showLoginPopup');
      document.body.classList.add('popup-visible');
    },
    loginPopupTry() {
      this.$store.commit('setLoginPopupTitle', 'Join Appshots')
      this.$store.commit('showLoginPopup');
      document.body.classList.add('popup-visible');
    },
    openCollection() {
      if (sessionStorage.isAnyCollectionAvailable == "true") {
        this.$router.push("/collections/");
      } else {
        window.openCreateCollectionPopup();
      }
    },
    headerLogoClick() {
      this.$store.commit("setSelectedCategories", []);
      this.$store.commit("setReloadPage");
    },
    handlePlatformOptionClick(option) {
      this.selected = option;
      this.$store.commit("setSelectedPlatform", option);
      this.$store.commit("setSelectedCategories", []);
      this.$store.commit("setReloadPage");
      this.$router.push("/apps/");
      this.showCommonMenu = false;
    },
    logout() {
      var url = `${process.env.main_API_URL}/logout_user/`;
      axios({
        method: "get",
        url: url,
      }).then(function (response) { });
      this.$store.commit("setAuthenticated", false);
      document.location.href = "/";
      // Check google, account, id exists
      if (
        window.google &&
        window.google.accounts &&
        window.google.accounts.id
      ) {
        window.google.accounts.id.disableAutoSelect();
      }
    },
    getHeaderHeight: function () {
      setTimeout(() => {
        const headerHeight = document.querySelector("#header .container").clientHeight;
        document.getElementsByClassName("header-space")[0].style.height = headerHeight + "px";

        let scrollpos = window.scrollY;
        const header = document.getElementById("header");

        function add_class_on_scroll() {
          header.classList.add("scrolled");
        }

        function remove_class_on_scroll() {
          header.classList.remove("scrolled");
        }

        if (!this.scrollEventAdded) {
          window.addEventListener('scroll', () => {
            scrollpos = window.scrollY;
            if (scrollpos > 50) {
              add_class_on_scroll();
            } else {
              remove_class_on_scroll();
            }
          });
          this.scrollEventAdded = true; // Ensure the scroll event is added only once
        }
      }, 100);
    },
    toggleCommonMenu() {
      this.showMusicBox = false
      this.showCommonMenu = !this.showCommonMenu
    },
    toggleUserMenu() {
      this.showMusicBox = false
      this.showUserMenu = !this.showUserMenu
    },

  },
  computed: {
    isLoginPopupVisible() {
      return this.$store.getters.isLoginPopupVisible;
    },
    siteTheme() {
      return this.$store.getters.getSiteTheme;
    },
    isHeaderPlatforHidden() {
      // Return true for pages other than home and selected paths
      return !["/"].includes(this.$route.path);
    },
    currentUser() {
      return this.$store.state.globalUserData;
    },
    isDarkTheme() {
      return this.theme === "dark";
    },
    getHeaderClass() {
      var headerClass = "";
      if (this.theme === "dark") {
        headerClass += "header bg-black";
      } else {
        headerClass += "header bg-white";
      }
      headerClass += " election-header";
      return headerClass;
    },
  },
  components: { DynamicSnackBar },
};
