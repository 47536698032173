
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  data() {
    return {
      showLoading: false,
      toggleEditMode: false
    }
  },
  methods: {
    createCollection() {
      let context = this
      // show loader 
      context.showLoading = true

      var url = `${process.env.main_API_URL}/create_or_update_collection/`;

      url = new URL(url);

      let data = new FormData()

      let popup = document.getElementById('create-collection-popup')
      let collectionId = popup.dataset.collectionId
      if (collectionId != undefined && collectionId !== "undefined") {
        data.append('collection_slug_url', collectionId)
      }

      const name = collection_name_input.value
      const description = collection_description_input.value

      if (name === '') {
        context.showToaster('Please enter a name for your collection', 'error');
        context.showLoading = false
        return
      }

      data.append('name', name)
      data.append('description', description)

      axios
        .post(url.toString(), data)
        .then(function (response) {

          context.showLoading = false
          create_collection_popup_close_button.click()
          context.showToaster('Added to collection successfully', 'success');
          sessionStorage.isAnyCollectionAvailable = 'true'
        })
        .catch(function (error) {
          let data = error.response.data
          if (data.toast) {
            create_collection_popup_close_button.click()
            context.showToaster(data.toast);
          }
          context.showLoading = false
          context.showToaster('Something went wrong. Please try again later.', 'error');
        });

    }
  }
}
