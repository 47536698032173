
export default {
    props: {
        onSubmitAppRequest: { type: Function, required: true },
        onCloseRequestNewApplicationForm: { type: Function, required: true },
        onCancelRequestNewApplicationSuccessMessage: { type: Function, required: true },
        isStoringNewAppRequest: Boolean,
        isSubmissiomSuccess: Boolean,
    },
    data() {
        return {
            selectedPlatform: ['iOS'],
            choosePlatform: ['iOS', 'Android', 'Web'],
            textInputAppName: '',
            textInputAppRegion: '',
            textInputAppNote: '',
            textInputError: {
                platformError: '',
                appNameError: '',
                appRegionError: ''
            }
        }
    },
    methods: {
        onSelectPlatform(platform) {
            if (this.selectedPlatform.includes(platform)) {
                this.selectedPlatform = this.selectedPlatform.filter(item => item !== platform)
            } else {
                this.selectedPlatform.push(platform)
            }
        },
        onClickSubmitAppRequest() {
            if (!this.textInputAppName || !this.textInputAppRegion || this.selectedPlatform.length === 0) {
                this.textInputError.platformError = this.selectedPlatform.length > 0 ? '' : 'required'
                this.textInputError.appNameError = !!this.textInputAppName ? '' : 'required'
                this.textInputError.appRegionError = !!this.textInputAppRegion ? '' : 'required'
                setTimeout(() => {
                    this.textInputError = {
                        platformError: '',
                        appNameError: '',
                        appRegionError: ''
                    }
                }
                    , 3000)
                return
            }
            if (this.isStoringNewAppRequest) return
            this.onSubmitAppRequest({ app_name: this.textInputAppName, platforms: this.selectedPlatform, notes: this.textInputAppNote, app_region: this.textInputAppNote })
        },
        onclickRequestApplication() {
            this.textInputAppName = '';
            this.textInputAppRegion = '';
            this.textInputAppNote = '';
            this.onCancelRequestNewApplicationSuccessMessage()
        }
    }
}
