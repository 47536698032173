import { render, staticRenderFns } from "./SearchItemComponent.vue?vue&type=template&id=623b0dc8&scoped=true"
import script from "./SearchItemComponent.vue?vue&type=script&lang=js"
export * from "./SearchItemComponent.vue?vue&type=script&lang=js"
import style0 from "./SearchItemComponent.vue?vue&type=style&index=0&id=623b0dc8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "623b0dc8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NotifiedSubscribedButton: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/advancedsearch/NotifiedSubscribedButton.vue').default})
