
export default {
    props: {
        selectedCategory: String,
        textString: String,
        onPressCategory: Function,
        selectedAppCategory: String
    },
    computed: {
        statusActiveClassContainer() {
            if (this.selectedCategory === this.textString) {
                return 'search-category-container__active'
            }
            return 'search-category-container'
        },
        statusActiveTextContainer() {
            if (this.selectedCategory === this.textString) {
                return 'search-category-text__active'
            }
            return 'search-category-text'
        },
        statusActiveImageContainer() {
            return this.selectedCategory === this.textString ? '#A89966' : '#AFAFAF'
        },

    }
}
