
export default {
    props: {
        isSubscribed: Boolean,
        itemDetail: Object,
        handleClickGetNotification: Function
    },
    methods: {
        checkSubscribedAndSend(slug) {
            const upvotedAppShotsList = this.$store.state.upvotedAppShots
            const filteredDataIndex = upvotedAppShotsList.findIndex(element => element === slug)
            if (filteredDataIndex > -1) return
            this.handleClickGetNotification(slug);
        }
    },
    computed: {
        isSubscribedString() {
            const upvotedAppShotsList = this.$store.state.upvotedAppShots
            const filteredDataIndex = upvotedAppShotsList.findIndex(element => element === this.itemDetail.slug)
            return filteredDataIndex > -1 ? 'Subscribed' : 'Get Notified'
        },
        isSubscribingAppShot() {
            return this.$store.state.appshotSubscribeSlug
        }
    }
}
