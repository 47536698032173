
import SearchItemComponent from './SearchItemComponent.vue';

export default {
    props: {
        similarAppList: Array,
        handleClickOnItem: Function,
        getCurrentItemHighlighted: Function
    },
    components: { SearchItemComponent }
}
