
import NotifiedSubscribedButton from './NotifiedSubscribedButton.vue';

export default {
    props: {
        isComingSoonItem: Boolean,
        itemDetail: Object,
        isItemHighlighted: Boolean,
        handleClickOnItem: Function,
        onHandleGetNotificationClick: Function
    },
    data() {
        return {
            isHovered: false,
        };
    },
    computed: {
        containerClass() {
            if (this.isItemHighlighted) {
                return 'search-item-container-highlight'
            }
            return this.isHovered ? 'search-item-container-highlight' : 'search-item-container';
        },
        isComingSoonItemColor() {
            return this.isComingSoonItem ? "search-item-text-container__title-comingsoon" : "search-item-text-container__title";
        }
    },
    components: { NotifiedSubscribedButton }
}
