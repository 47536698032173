
export default {
    methods: {
        handleClick() {
            this.$store.commit("setShowSearchPopup", true);
        },
    },
    mounted() {
        document.addEventListener("keydown", (event) => {
            if (this.isMacOS()) {
                if (event.metaKey && event.key === "k") {
                    event.preventDefault();
                    this.handleClick();
                }
            } else {
                if (event.ctrlKey && event.key === "k") {
                    event.preventDefault();
                    this.handleClick();
                }
            }
        });
    },
    beforeDestroy() {
        document.removeEventListener("keydown", this.handleKeyDown);
    },
};
