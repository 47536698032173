import Vue from 'vue'
import axios from "axios";
import confetti from 'canvas-confetti';

axios.defaults.withCredentials = true;

Vue.mixin({
  data: function () {
    return {
      g_collections: []
    }
  },
  methods: {
    mobileCheck() {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-|)|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    addRefToUrl(url) {
      if (typeof url === 'string') {
        url = new URL(url);
      }
      url.searchParams.set('ref', 'appshotsdesign');
      return url;
    },
    loadAllCollectionsGlobal() {
      let context = this;
      var url = `${process.env.main_API_URL}/get_all_collections/`;
      url = new URL(url);
      axios.get(url.toString()).then(function (response) {
        let data = response.data;

        context.g_collections = data.collections;

      });
    },
    isDebug() {
      return process.env.NODE_ENV === 'development'
    },
    isMacOS() {
      var isMacOS = false
      if (process.client) {
        isMacOS = navigator.platform.includes('Mac');
      }
      return isMacOS
    },
    isDesktopApp() {
      // Renderer process
      if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
      }

      // Main process
      if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
      }

      // Detect the user agent when the `nodeIntegration` option is set to true
      if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true;
      }

      return false;
    },
    isAuthenticated() {
      return this.$store.state.isAuthenticated
    },
    getUserData() {
      return this.$store.state.globalUserData
    },
    convertToArray(arrayData) {
      var array = [];
      for (var k in arrayData) {
        array.push(arrayData[k]);
      }
      return array;
    },
    snakeCase(string) {
      return string.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_');
    },
    getDocumentHeight() {
      const body = document.body;
      const html = document.documentElement;

      return Math.max(
        body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight
      );
    },
    getScrollTop() {
      return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    },
    getLastKey(snapshot) {
      return Object.keys(snapshot.val()).sort().pop();
    },

    onPaginationSidebar(callbackHandler, divSelector) {
      let context = this;
      // Select the div
      const scrollDiv = document.querySelector(divSelector);
    
      function scrollHandler() {
        if (!context.$store.state.stopPagination) {
          // Adjusted to use div's scroll properties
          if (scrollDiv.scrollTop + scrollDiv.clientHeight >= scrollDiv.scrollHeight - 150)
            return;
          callbackHandler();
          // Remove the event listener after it's been triggered once
          scrollDiv.removeEventListener('scroll', scrollHandler);
          setTimeout(enableScrollListener, 1000);
        }
      }
    
      // Attach the scroll event listener initially to the div
      scrollDiv.addEventListener('scroll', scrollHandler);
    
      // Function to re-enable the scroll event listener for the div
      function enableScrollListener() {
        scrollDiv.addEventListener('scroll', scrollHandler);
      }
    },

    onPagination(callbackHandler) {
      let context = this;
      function scrollHandler() {
        if (!context.$store.state.stopPagination) {
          if (context.getScrollTop() < context.getDocumentHeight() - window.innerHeight - 600)
            return;
          callbackHandler();
          // Remove the event listener after it's been triggered once
          window.removeEventListener('scroll', scrollHandler);
          setTimeout(enableScrollListener, 1000);
        }
      }

      // Attach the scroll event listener initially
      window.addEventListener('scroll', scrollHandler);

      // Function to re-enable the scroll event listener
      function enableScrollListener() {
        window.addEventListener('scroll', scrollHandler);
      }
    },
    createToaster: function (type = "") {
      const toasterItem = document.createElement("div");
      toasterItem.classList.add("aps-toaster", "show-toaster");
      if (type) {
        toasterItem.classList.add(type); // Add the type as a class if provided
      }
      document.body.appendChild(toasterItem);
      toasterItem.innerText = "";
      setTimeout(function () {
        toasterItem.parentNode.removeChild(toasterItem);
      }, 2000);
    },
    showToaster: function (text = "Changes Saved Successfully", type = "") {
      // Pass the type to createToaster
      this.createToaster(type);
      document.querySelectorAll(".aps-toaster")[0].innerHTML = text;
    },
    // load script method
    loadPaypalScript(callbackHandler) {
      if (!this.isAuthenticated()) {
        return;
      }

      const clientId = process.env.PAYPAL_CLIENT_ID;
      const script = document.createElement("script");
      // append debug=true to the end of the url if debugging
      if (process.env.NODE_ENV === "development") {
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&components=buttons&vault=true&intent=subscription&debug=true`;
      } else {
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&components=buttons&vault=true&intent=subscription`;
      }
      // script.setAttribute("data-client-token", this.$store.state.globalUserData.email);
      script.setAttribute("data-page-type", "product-listing");
      script.async = true;
      script.onload = callbackHandler;

      document.head.appendChild(script);
    },
    renderPaypalSubscriptionButton() {
      const userSlugUrl = this.$store.state.globalUserData.slug;

      // debug check
      var yearlyPlanButtonPlanId = 'P-2AX19339DX522225UMWJJV5Q';
      var quaterlyPlanButtonPlanId = 'P-38173120AK693470XMWJJS7A';
      var monthlyPlanButtonPlanId = 'P-8N496121RJ068471PMWJJSHI';

      if (process.env.NODE_ENV === "development") {
        monthlyPlanButtonPlanId = 'P-2DB46878K0561615KMTL5I2A';
        quaterlyPlanButtonPlanId = 'P-18H1028080256463HMTL53KI';
        yearlyPlanButtonPlanId = "P-8P832181B2163623LMTNF46Y";
      }

      let context = this;

      if (document.querySelector('#paypal-button-1m-pro')) {
        paypal.Buttons({
          style: {
            shape: 'pill',
            color: 'black',
            layout: 'vertical',
            label: 'subscribe',
            height: 48
          },
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              plan_id: monthlyPlanButtonPlanId,
              custom_id: userSlugUrl
            });
          },
          onApprove: function (data, actions) {
            handlePaymentProccesing(data);
          }
        }).render("#paypal-button-1m-pro");
      }

      if (document.querySelector('#paypal-button-3m-pro')) {
        paypal.Buttons({
          style: {
            shape: 'pill',
            color: 'black',
            layout: 'vertical',
            label: 'subscribe'
          },
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              /* Creates the subscription */
              plan_id: quaterlyPlanButtonPlanId,
              custom_id: userSlugUrl
            });
          },
          onApprove: function (data, actions) {
            handlePaymentProccesing(data);
          },
        }).render('#paypal-button-3m-pro');
      }

      if (document.querySelector('#paypal-button-12m-pro')) {
        paypal.Buttons({
          style: {
            shape: 'pill',
            color: 'black',
            layout: 'vertical',
            label: 'subscribe',
            height: 48
          },
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              plan_id: yearlyPlanButtonPlanId,
              custom_id: userSlugUrl
            });
          },
          onApprove: function (data, actions) {
            handlePaymentProccesing(data);
          }
        }).render("#paypal-button-12m-pro");
      }

      function handlePaymentProccesing(data) {
        const url = new URL('/paymentProcessing/', window.location.origin);
        url.searchParams.append('orderID', data.orderID);
        url.searchParams.append('subscriptionID', data.subscriptionID);
        url.searchParams.append('paymentSource', data.paymentSource);
        window.location.href = url.toString();
      }
    },
    renderConfetti() {
      const duration = 3000; // 3 seconds
      const animationEnd = Date.now() + duration;

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      const interval = setInterval(function () {
        if (Date.now() > animationEnd) {
          clearInterval(interval);
          return;
        }

        // Use a smaller particle count
        const particleCount = 30 + Math.floor(Math.random() * 31);

        // Animate confetti from various parts of the screen
        confetti({
          particleCount: particleCount,
          startVelocity: randomInRange(20, 40),
          spread: randomInRange(50, 60),
          origin: { x: randomInRange(0, 0.25), y: Math.random() },
          zIndex: 0,
          colors: ['#ff3838', '#ff9d76', '#32ff7e', '#7efff5'], // Fewer colors
          shapes: ['circle'], // Limiting shapes to circles
          scalar: randomInRange(0.8, 1)
        });

        confetti({
          particleCount: particleCount,
          startVelocity: randomInRange(20, 40),
          spread: randomInRange(50, 60),
          origin: { x: randomInRange(0.75, 1), y: Math.random() },
          zIndex: 0,
          colors: ['#ff3838', '#ff9d76', '#32ff7e', '#7efff5'], // Fewer colors
          shapes: ['circle'], // Limiting shapes to circles
          scalar: randomInRange(0.8, 1)
        });

      }, 350); // Burst every 350 milliseconds
    }   
  }
})
